.container {
  text-align: center;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 15px;
    line-height: 15px;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    color: var(--charcoal);

    &:hover {
      opacity: .5;
    }
  }
}

.inputContainer {

  .label {
    font-size: 13px;
    font-weight: 500;
    color: #444444;
    display: block;
    margin-bottom: 5px;
  }

  .input {
    font-family: inherit;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #E3E5E5;
    background: white;
    padding: 0 14px;
    font-size: 16px;
    color: var(--charcoal);
    font-weight: 400;
    width: 290px;
    transition: border-color .3s ease;
    box-sizing: border-box;

    &::placeholder {
      color: var(--descriptionGrey);
      font-size: 16px;
    }

    &:focus {
      outline: none;
      border-color: var(--primaryGreen);
    }
  }

  .inputWrapper {
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: var(--descriptionGrey) !important;
  }
}
