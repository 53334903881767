@import "../../style/media-query.scss";

.login {
  display: flex;
  flex-grow: 1;
}

.image {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  img {
    max-width: 50%;
  }
}

.formWrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 440px;
  padding: 0 20px;
  background: white;
  border-radius: 15px;
  height: fit-content;
  margin: auto;

  img {
    width: 200px;
    max-width: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .label {
    font-size: 13px;
    font-weight: 500;
    color: #444444;
  }

  .inputWrapper {
    position: relative;
    width: 100%;
    max-width: 440px;

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: 0;
      color: var(--grey);
      font-size: 16px;
      padding: 8px;
      cursor: pointer;
      width: auto;
    }
  }

  input {
    font-family: inherit;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #E3E5E5;
    background: white;
    padding: 16px;
    padding-right: 40px;
    font-size: 16px;
    color: var(--descriptionGrey);
    font-weight: 400;
    line-height: 16px;
    width: 100%;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: var(--descriptionGrey) !important;
  }
}

.errorBorder,
// overwrites the default border-color of Grommet checkbox:
input[type="checkbox"][class*="errorBorder"]+div {
  border-color: var(--textErrorRed) !important;
}

label>div {
  // overwrites the default margin-right (12px) of Grommet checkbox:
  margin-right: 8px !important;
}

.errorSection {
  font-size: 12px;
  min-height: 24px;
  width: 100%;
  text-align: left;

  p {
    text-align: center;
    margin: 0;
  }

  &.active {
    color: var(--textErrorRed);
  }
}

.reset {
  background: unset;
  border: 0;
  color: #2F64B8;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
}

.textStyle {
  color: var(--grey);
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.textStyleAlternative {
  color: var(--grey);
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.separator {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 24px;
  user-select: none;
  color: var(--mediumGrey);

  span {
    padding: 0 20px;
  }

  hr {
    height: 1px;
    flex-grow: 1;
    border: 0;
    background: var(--mediumGrey);
  }
}

.socialLogin {
  display: flex;

  a:nth-child(2) {
    margin: 0 0 20px auto;
  }
}

.navigationContainer {
  display: none;

  span a {
    text-decoration: none;
    color: var(--blue);
  }
}

@media screen and (max-width: #{$tablet-portrait-width}) {

  .navigationContainer {
    display: block;
    text-align: center;
  }


}
